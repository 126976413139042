import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './PricingSection.css';
import fetchApi from '../../modules/fetchapi';


const PricingSection = () => {

  const [pricing, setPricing] = useState([]);

  const ordering = {
    'Basic':    1,
    'Standard': 2,
    'Enhanced': 2,
    'Premium':  3,
  };

  const fadeInClass = ['fadeInLeft', 'fadeInUp', 'fadeInRight'];

  const sortingParam = (item) => {
    return ordering[item?.packageName] ?? 0;
  };

  const orderPackages = (list) => {
    list.sort((a, b) => sortingParam(a) - sortingParam(b) );
    return list;
  };

  const addData = (list) => {
    list.forEach( (item, index) => {
      item.fadeInClass = fadeInClass[index] ?? '';
      item.imgSrc = 'assets/images/shapes/pricing-icon-1-' + (index+1) + '.png';
    } );
    return list;
  };

  useEffect(() => {
    fetchApi(`/pricings`)
      .then((res) => res.json())
      .then(orderPackages)
      .then(addData)
      .then((info) => setPricing(info));
  }, []);

  return (

    <>

      <section className="pricing-one" id='pricing-sec'>
        <div className="container">
          <div className="block-title text-center">
            <p className="color-2"><span>Pricing Plan</span></p>
            <h3>No Any Hidden Charge Select <br /> <span>Your Pricing Plan</span></h3>
          </div>
          <div className="row high-gutters">

          {
              pricing.map(p => <div className={`col-lg-4 wow ${p.fadeInClass}`} data-wow-duration="1500ms">
              <div className="pricing-one__single">
                <div className="pricing-one__icon">
                  <img src={p.imgSrc} alt />
                </div>{/* /.pricing-one__icon */}
                <h3>{p.packageName} Plan</h3>
                <ul className="pricing-one__list list-unstyled">
                  <li>{p.packagePointOne}</li>
                  <li>{p.packagePointTwo}</li>
                  <li>{p.packagePointThree}</li>
                  <li>{p.packagePointFour}</li>
                  <li>{p.packagePointFive}</li>
                  <li>{p.packagePointSix}</li>
                  <li>{p.packagePointSeven}</li>
                </ul>{/* /.pricing-one__list list-unstyled */}
                <p>${p.packagePrice}</p>
               
                <Link to={`/order-now/${p._id}`} className="thm-btn pricing-one__btn">
                     {p.packageButtonText} 
                    </Link>
              </div>{/* /.pricing-one__single */}
            </div> )}
           
            
          </div>
        </div>
      </section>

    </>
  );
};

export default PricingSection;
