import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import HeaderBottom from '../components/HomePage/HeaderBottom';
import fetchApi from '../modules/fetchapi'

const ContactUs = () => {

  const navigate = useNavigate();
  const [footerAddress, setFooterAddress] = useState([]);
  const [action, setAction] = useState([]);
  const recaptchaRef = useRef(null);
  const [responseMessage, setResponseMessage] = useState('⏳ Wait...');
  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    // Set a timer to enable the form after 3 seconds
    const timer = setTimeout(() => {
      clearTimeout(timer)
      setDisabled(false);
      setResponseMessage('');
    }, 5000);
  }, []);

  useEffect(() => {
    fetchApi(`/footer-address`)
      .then((res) => res.json())
      .then((info) => setFooterAddress(info));
  }, []);


  useEffect(() => {
    fetchApi(`/actions`)
      .then((res) => res.json())
      .then((info) => setAction(info));
  }, []);


  const handleMessage = async (event) => {
    event.preventDefault();

    let recaptchaToken = null;

    setResponseMessage('⏳ Sending...');
    setDisabled(true);

    try {
      recaptchaToken = await recaptchaRef.current.executeAsync();
      console.log('reCapcha token Ok.');
    }
    catch (e) {
      console.log('reCapcha err.:', e);
    }

    recaptchaRef.current.reset();

    if (!recaptchaToken) {
      setResponseMessage('❌ CAPTCHA Error, try again :(');
      return;
    }

    const name = event.target.name.value;
    const email = event.target.email.value;
    const subject = event.target.subject.value;
    const message = event.target.message.value;
    const messageStatus = event.target.messageStatus.value;


    const addItem = {
      name,
      email,
      subject,
      message,
      messageStatus,
    };

    fetchApi(`/add-message`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
        'X-Recaptcha-Token': recaptchaToken,
      },
      body: JSON.stringify(addItem),
    })
    .then((res) => res.json())
    .then((res) => {
      setResponseMessage('✅ Message is Sent. Thanks');
    })
    .catch((e) => {
      setResponseMessage('⚠️ Sending failed, please try again!');
      console.error('Sending error:', e);
    });
  };



  return (



    <>

      <section className="contact-one" id='contact-sec'>
        <div className="container wow fadeInUp" data-wow-duration="1500ms">
          <div className="inner-container">
            <img src="https://i.ibb.co/d40G9rx/contact-1-moc-1.png" className="contact-one__shape-2" alt />
            <img src="https://i.ibb.co/wgnm1DQ/contact-1-moc-2.png
https://i.ibb.co/d40G9rx/contact-1-moc-1.png" className="contact-one__shape-3" alt />
            <div className="block-title text-center">
              <p><span>Analysis</span></p>
              <h3>Get Free SEO Analysis?</h3>
            </div>{/* /.block-title text-center */}
            <form onSubmit={handleMessage} action="assets/inc/sendemail.php" className="contact-one__form">
              <div className="row">
                <div className="col-md-6">
                  <input type="text" name="name" placeholder="Your Name*" disabled={disabled} />
                </div>{/* /.col-md-6 */}
                <div className="col-md-6">
                  <input type="text" name="email" placeholder="Email*" disabled={disabled} />
                </div>{/* /.col-md-6 */}
                <div className="col-md-12">
                  <input type="text" name="subject" placeholder="Subject*" disabled={disabled} />
                </div>{/* /.col-md-6 */}
                <div className="col-md-6">
                  <input id="input_phone" type="tel" name="messageStatus" value="UnRead" hidden placeholder="value" disabled={disabled} />
                </div>{/* /.col-md-6 */}
                <div className="col-md-12">
                  <input type="text" name="message" placeholder="Message" disabled={disabled} />
                </div>{/* /.col-md-6 */}
                <ReCAPTCHA
                  sitekey="6LdJBEcqAAAAALjJExUnjAfWDuhbMdg0BEtDRPJO" // Public Key
                  ref={recaptchaRef}
                  size="invisible"
                />
                <div className="col-md-12 text-center">
                  <button type="submit" className="thm-btn contact-one__btn" disabled={disabled}>Send Now</button>
                  {responseMessage && <p>{responseMessage}</p>}
                </div>{/* /.col-md-12 */}
              </div>{/* /.row */}
            </form>{/* /.contact-one__form */}
          </div>{/* /.inner-container */}
        </div>{/* /.container */}
      </section>{/* /.contact-one */}


    </>
  );
};

export default ContactUs;
