import auth from '../firebase.init';
import { getIdToken } from 'firebase/auth';


//var serverRootUrl = 'https://recreationalservices12clone-f96bc978f0ea.herokuapp.com';
var serverRootUrl = 'https://us-central1-recreationalservices12clone.cloudfunctions.net/api';
var publicApiKey = '8950432nklcdRET6587KLPcfr532';

export default async function fetchApi(path, options={}) {

    var userToken = '';

    if (auth?.currentUser) {
        userToken = await getIdToken(auth.currentUser);
    }

    //console.log('API call: ' + path);

    options.headers = options.headers || {};

    Object.assign(options.headers, {
            'Authorization': 'Bearer ' + userToken,
            'x-api-key': publicApiKey,
          });

    return fetch(serverRootUrl + path, options);
}

