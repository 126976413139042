// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCFK1NCo0PCwGVLE4NjC2BHC4zqshg7L-4",
  authDomain: "recreationalservices12clone.firebaseapp.com",
  projectId: "recreationalservices12clone",
  storageBucket: "recreationalservices12clone.appspot.com",
  messagingSenderId: "639678511303",
  appId: "1:639678511303:web:1c065f14dd79c07552e6e2"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const auth = getAuth(app);

export default auth;