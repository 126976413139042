import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import { useAuthState } from "react-firebase-hooks/auth";
import auth from "../../firebase.init";
import { signOut } from "firebase/auth";
import fetchApi from '../../modules/fetchapi';

import './NavBar.css';


const NavBar = () => {

  const [user, loading] = useAuthState(auth);
  const handleSignOut = () => {
    signOut(auth);
  }
  const [users, setUser] = useState([]);
  const [logo, setLogo] = useState([]);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    fetchApi(`/logo`)
      .then((res) => res.json())
      .then((info) => setLogo(info));
  }, []);

/*
  useEffect(() => {
    const timer = setTimeout(() => {
      fetchApi(`/users`)
        .then((res) => res.json())
        .then((info) => setUser(info))
    }, 1000); // 1 second delay
  }, [user, loading]);
*/

  useEffect(() => {
    fetchApi(`/users`)
      .then((res) => res.json())
      .then((info) => setUser(info))
  }, [user, loading]);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  const location = useLocation();
  useEffect(() => {
    // Close menu when the route changes
    closeMenu();
  }, [location]);

  return (



    <>
 <nav className="main-nav-one stricky">
  <div className="container-fluid">
    <div className="inner-container">
      <div className="logo-box">
        {
          logo.map(l=> <a href="/">
          <img src={l.logo} alt />
        </a> )
        }
       
      </div>{/* /.logo-box */}
      <div className="main-nav__main-navigation">
        <ul className="main-nav__navigation-box">
        
          <li><a href="/">Home</a></li>
          <li><a href="/#about-sec">About</a></li>
          <li><a href="/#services-sec">Services</a></li>
          <li><a href="/#pricing-sec">Pricing</a></li>
          <li><a href="/#contact-sec">Contact</a></li>
          
        </ul>{/* /.main-nav__navigation-box */}
      </div>{/* /.main-nav__main-navigation */}

      <div className="hamburger-nav__main-navigation">
        <span className={`side-menu__toggler ${isMenuOpen ? 'open' : ''}`} onClick={toggleMenu}><i className="fa fa-bars" /></span>
        <div className={`hamburger-nav__navigation-box ${isMenuOpen ? 'open' : ''}`}>
          <ul>
            <li><a href="/">Home</a></li>
            <li><a href="/#about-sec">About</a></li>
            <li><a href="/#services-sec">Services</a></li>
            <li><a href="/#pricing-sec">Pricing</a></li>
            <li><a href="/#contact-sec">Contact</a></li>
            <li className="hamburger-user">
              {
                user?.email ?
                <Link to="/dashboard" className="thm-btn main-nav-one__btn ">Dashboard</Link>
                :
                <Link to="/login" className="thm-btn main-nav-one__btn" >Log in</Link>
              }
              {
                users.map(u=> u.userEmail === user?.email && u.userRole === 'Admin' &&
                  <Link to="/admin" className="thm-btn main-nav-one__btn">
                    Admin
                  </Link>
                )
              }
            </li>
          </ul>
        </div>{/* /.hamburger-nav__navigation-box */}
      </div>{/* /.hamburger-nav__main-navigation */}

      <div className="main-nav__right">
              {
                user?.email ?
                <Link to="/dashboard" className="thm-btn main-nav-one__btn ">Dashboard</Link>
                :
                <Link to="/login" className="thm-btn main-nav-one__btn" >Log in</Link>
              }
              {
                users.map(u=> u.userEmail === user?.email && u.userRole === 'Admin' &&
                  <Link to="/admin" className="thm-btn main-nav-one__btn">
                    Admin
                  </Link>
                )
              }
              {/* /.thm-btn main-nav-one__btn */}
      </div>{/* /.main-nav__right */}
    </div>{/* /.inner-container */}
  </div>{/* /.container-fluid */}
</nav>{/* /.main-nav-one */}

    </>

    
  );
};

export default NavBar;
